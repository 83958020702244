import React from "react";

function TextIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 496.188 496.188"
      version="1.1"
      viewBox="0 0 496.188 496.188"
      xmlSpace="preserve"
    >
      <path d="M196.698 108.246c-5.006-16.197-19.999-27.241-36.96-27.241h-41.996c-17.006 0-32.037 11.116-37.01 27.396L1.629 367.257a36.989 36.989 0 005.657 32.902 37.088 37.088 0 0029.811 15.025c16.67 0 31.287-11.116 35.747-27.167l16.263-58.612h95.689l17.514 58.246c4.91 16.345 19.953 27.533 37.051 27.533a38.681 38.681 0 0031.156-15.742 38.694 38.694 0 005.809-34.42l-79.628-256.776zm-96.68 164.645l19.808-70.913c5.446-19.337 10.426-44.609 15.398-64.451h.946c4.974 19.842 10.959 44.625 16.881 64.451l20.832 70.913h-73.865zM495.093 362.394v-69.026c0-46.676-21.128-85.658-87.331-85.658-24.577 0-45.017 4.506-59.917 9.814-9.902 3.531-15.35 14.109-12.473 24.217l.197.665a20.179 20.179 0 0010.065 12.352 20.203 20.203 0 0015.87 1.253c10.85-3.661 23.292-6.082 35.309-6.082 30.462 0 36.154 15.028 36.154 25.582v2.853c-70.256-.415-116.538 24.346-116.538 75.915 0 31.68 23.953 60.897 64.171 60.897 23.534 0 43.813-8.538 56.824-24.37h1.198l1.596 8.685c1.207 6.496 6.864 11.221 13.482 11.221h28.767c3.824 0 7.467-1.587 10.054-4.384a13.672 13.672 0 003.626-10.358c-.747-9.775-1.054-21.509-1.054-33.576zm-60.126-23.954c0 3.649-.389 7.311-1.218 10.554-4.053 12.588-16.639 22.736-32.072 22.736-13.792 0-24.36-7.71-24.36-23.541 0-23.955 25.188-31.664 57.65-31.267v21.518z" fill={props.fill}></path>
    </svg>
  );
}

export default TextIcon;
