import React from "react";

function RedditIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="16"
      fill="none"
      viewBox="0 0 26 16"
      style={{position: 'relative', top: '-1px', verticalAlign: 'middle'}}
    >
      <path d="M9.94876 9.15973C9.94876 8.57986 10.419 8.1065 10.995 8.1065C11.5711 8.1065 12.0413 8.57986 12.0413 9.15973C12.0413 9.7396 11.5711 10.213 10.995 10.213C10.419 10.213 9.94876 9.7396 9.94876 9.15973Z" fill={props.fill}/>
      <path d="M15.7914 11.9407C15.0743 12.6626 13.7106 12.7099 13.3109 12.7099C12.9112 12.7099 11.5358 12.6508 10.8304 11.9407C10.7246 11.8342 10.7246 11.6567 10.8304 11.5502C10.9362 11.4437 11.1126 11.4437 11.2184 11.5502C11.6651 11.9999 12.6291 12.1656 13.3227 12.1656C14.0163 12.1656 14.9685 11.9999 15.427 11.5502C15.5328 11.4437 15.7091 11.4437 15.8149 11.5502C15.8972 11.6686 15.8972 11.8342 15.7914 11.9407Z" fill={props.fill}/>
      <path d="M14.557 9.15973C14.557 9.7396 15.0273 10.213 15.6033 10.213C16.1794 10.213 16.6496 9.7396 16.6496 9.15973C16.6496 8.57986 16.1794 8.1065 15.6033 8.1065C15.0273 8.1065 14.557 8.57986 14.557 9.15973Z" fill={props.fill}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16H18C22.4183 16 26 12.4183 26 8C26 3.58172 22.4183 0 18 0H8ZM18.5305 6.62726C19.3417 6.62726 20 7.28997 20 8.10652C20 8.71005 19.6356 9.23075 19.1536 9.46743C19.1771 9.60944 19.1888 9.75145 19.1888 9.90529C19.1888 12.1774 16.5673 14.0117 13.3227 14.0117C10.0781 14.0117 7.45651 12.1774 7.45651 9.90529C7.45651 9.75145 7.46827 9.59761 7.49178 9.4556C6.97452 9.21892 6.62185 8.71005 6.62185 8.10652C6.62185 7.28997 7.28018 6.62726 8.09133 6.62726C8.47927 6.62726 8.8437 6.79294 9.10233 7.04145C10.1133 6.29591 11.5123 5.83438 13.0758 5.78704L13.8164 2.26049C13.8399 2.18949 13.8752 2.13032 13.934 2.09482C13.9928 2.05932 14.0633 2.04748 14.1338 2.05932L16.5673 2.58001C16.7319 2.22499 17.0845 1.98831 17.496 1.98831C18.072 1.98831 18.5423 2.46167 18.5423 3.04154C18.5423 3.62141 18.072 4.09477 17.496 4.09477C16.9317 4.09477 16.4732 3.64508 16.4497 3.08888L14.2749 2.62735L13.6048 5.78704C15.1331 5.84621 16.5203 6.31958 17.5195 7.04145C17.7781 6.7811 18.1308 6.62726 18.5305 6.62726Z" fill={props.fill}/>
    </svg>
  );
}

export default RedditIcon;
